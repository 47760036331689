@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html {
  background-color: theme('colors.slate.900');
  background-image: url('/dots.svg');
  background-attachment: fixed;
  background-position: center -15px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

pre[class*='language-'] {
  color: theme('colors.slate.50');
}

.bg-dots {
  background-color: theme('colors.slate.900');
  background-image: url('/dots.svg');
  /* background-attachment: fixed; */
  background-position: center -15px;
}

.token.tag,
.token.class-name,
.token.selector,
.token.selector .class,
.token.selector.class,
.token.function {
  color: theme('colors.pink.400');
}

.token.attr-name,
.token.keyword,
.token.rule,
.token.pseudo-class,
.token.important {
  color: theme('colors.slate.300');
}

.token.module {
  color: theme('colors.pink.400');
}

.token.attr-value,
.token.class,
.token.string,
.token.property {
  color: theme('colors.sky.300');
}

.token.punctuation,
.token.attr-equals {
  color: theme('colors.slate.500');
}

.token.unit,
.language-css .token.function {
  color: theme('colors.teal.200');
}

.token.comment,
.token.operator,
.token.combinator {
  color: theme('colors.slate.400');
}

.post {
  line-height: 1.7;
  font-size: 17px;
}
.post > * {
  margin-bottom: 2rem;
}
.post .kg-code-card {
  border: 1px solid theme('colors.slate.200');
  border-bottom-left-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
}
.post .kg-code-card figcaption {
  border-top: 1px solid theme('colors.slate.200');
  font-size: 13px;
  padding: 6px;
}
.post a {
  text-decoration: underline;
  color: theme('colors.orange.600');
}
.post table {
  display: block;
  width: 100%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  overflow: auto;
}
.post table tr {
  border-top: 1px solid #333;
}
.post table th,
.post table td {
  padding: 6px 13px;
  border: 1px solid #333;
}
.post table th {
  font-weight: semibold;
  white-space: nowrap;
}
.post table tr:nth-child(2n) {
  background-color: rgb(255 255 255/10%);
}
.post blockquote {
  border-left: 4px solid theme('colors.orange.600');
  padding-left: 2rem;
}
.post a.anchor:after {
  content: '#';
  color: rgb(255 255 255/50%);
}
.post a.anchor {
  visibility: hidden;
  position: absolute;
  text-decoration-line: none;
  margin-left: -1em;
  padding-right: 0.5em;
  cursor: pointer;
}
.post :hover > .anchor {
  visibility: visible;
}
.post img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.post ul,
.post ol {
  padding-left: 2rem;
}
.post ul {
  list-style: inside;
}
.post ol {
  list-style: decimal;
}
.post :not(pre) > code {
  font-size: 0.86em;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  border: 1px solid theme('colors.slate.200');
  padding: 0.2em 0.3em;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  border-radius: 0.4rem;
}
.post :where(h2, h3, h4, h5) {
  scroll-margin-top: 2rem;
}
.post h2,
.post h3,
.post h4,
.post h5 {
  color: theme('colors.slate.900');
  font-weight: bold;
  line-height: 1.4;
}
.post > * + h2,
.post > * + h3 {
  margin-top: 3rem;
}
.post > h2 {
  font-size: 2rem;
}
.post > h3 {
  font-size: 1.6rem;
}
.post > h4 {
  font-size: 1.2rem;
}

.post code[class*='language-'],
.post pre[class*='language-'] {
  color: #f8f8f2;
  background: 0 0;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.post pre[class*='language-'] {
  padding: 1em;
  overflow: auto;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
}
.post :not(pre) > code[class*='language-'],
.post pre[class*='language-'] {
  background: #272822;
}
.post :not(pre) > code[class*='language-'] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}
.post .token.cdata,
.post .token.comment,
.post .token.doctype,
.post .token.prolog {
  color: #8292a2;
}
.post .token.punctuation {
  color: #f8f8f2;
}
.post .token.namespace {
  opacity: 0.7;
}
.post .token.constant,
.post .token.deleted,
.post .token.property,
.post .token.symbol,
.post .token.tag {
  color: #f92672;
}
.post .token.boolean,
.post .token.number {
  color: #ae81ff;
}
.post .token.attr-name,
.post .token.builtin,
.post .token.char,
.post .token.inserted,
.post .token.selector,
.post .token.string {
  color: #a6e22e;
}
.post .language-css .token.string,
.post .style .token.string,
.post .token.entity,
.post .token.operator,
.post .token.url,
.post .token.variable {
  color: #f8f8f2;
}
.post .token.atrule,
.post .token.attr-value,
.post .token.class-name,
.post .token.function {
  color: #e6db74;
}
.post .token.keyword {
  color: #66d9ef;
}
.post .token.important,
.post .token.regex {
  color: #fd971f;
}
.post .token.bold,
.post .token.important {
  font-weight: 700;
}
.post .token.italic {
  font-style: italic;
}
.post .token.entity {
  cursor: help;
}
.post pre[class*='language-'].line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber;
}
.post pre[class*='language-'].line-numbers > code {
  position: relative;
  white-space: inherit;
}
.post .line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  letter-spacing: -1px;
  border-right: 1px solid #999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.post .line-numbers-rows > span {
  display: block;
  counter-increment: linenumber;
}
.post .line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #999;
  display: block;
  padding-right: 0.8em;
  text-align: right;
}
